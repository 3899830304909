/**
 * GroupSelector2
 * Select2と最新のkanrinmaru-v2テーマを利用したグループ選択コンポーネント。
 *
 * GroupSelectorと比較して以下の点が異なる。
 *
 * - multiple属性を持たず、複数選択には対応していない
 * - 強制的にドロップダウンを下に表示する
 * - 選択肢にない値を入力・選択できない
 *
 **/

import React from "react";
import Select2 from "./Select2";
import { SelectFilterNote } from "./SelectFilterNote";

const I18N_SCOPE = "javascript.group_selector";

// Select2に渡すデータフォーマットに変換する
function select2Data(options: { id: string; name: string }[]): { id: string; text: string }[] {
  return options.map((option) => {
    return { id: option.id, text: option.name };
  });
}

export function GroupSelector2(props: {
  disabled: boolean;
  placeholder: string | undefined;
  options: { id: string; name: string }[];
  onChange: (groups: { id: string; name: string }[]) => void;
  selectedGroupId: string | undefined;
}) {
  const placeholder = props.placeholder || window.I18n.t(`${I18N_SCOPE}.placeholder`);

  // グループ選択時の処理
  // 選択されたグループを親コンポーネントが想定している渡されたデータフォーマットに変換して通知
  function handleOnSelect(e: JQuery.TriggeredEvent): void {
    if (e.target.value == null || e.target.value === "") {
      return;
    }

    const groups = props.options
      .filter((option) => option.id.toString() === e.target.value)
      .map((option) => ({ id: option.id, name: option.name }));
    props.onChange(groups);
  }

  // ジョブの新規作成フォームでは「文字列による絞り込み選択が可能です」というメッセージを表示する
  return (
    <>
      {!props.disabled && SelectFilterNote({})}
      <Select2
        className="ca-group-name-selector required"
        disabled={props.disabled}
        id="GroupSelector"
        data={select2Data(props.options)}
        onSelect={handleOnSelect}
        value={props.selectedGroupId}
        options={{
          dropdownAdapter: window.jQuery.fn.select2.amd.require("ForceDropDownAdapter"),
          forceDropdownPosition: "below",
          language: window.jQuery.fn.select2.amd.require(`select2/i18n/${window.I18n.locale}`),
          placeholder: placeholder,
          theme: "kanrinmaru-v2",
          width: "500px",
        }}
      />
    </>
  );
}
