import React from 'react';
import PropTypes from 'prop-types';

import GroupIconTip from '../GroupIconTip.jsx';
import PostProcessAvatar from './PostProcessAvatar.jsx';
import PostProcessButtons from './PostProcessButtons.jsx';
import SortableTableHeaderCell from '../SortableTableHeaderCell.jsx';

const I18n = window.I18n; // i18n-js

// 後処理一覧のテーブルに表示される列の数
const TABLE_COLUMNS = 7;

/**
 * 後処理一覧テーブルコンポーネント
 *
 * このコンポーネントの描画結果はTABLE要素となります。
 */
export default class PostProcessTable extends React.Component {
  /**
   * propTypes
   * @return {Object}
   * @property {?string} errorMessage - エラーメッセージ
   * @property {func} onSort - ソートボタンを押した時に呼び出されるコールバック関数
   * @property {boolean} policySetsAvailable 組織が構成レビュー機能を利用可能かどうか
   * @property {Array<Object>} postProcesses - groups - `[{id: 1, name: "hoge"}]` のようなグループ一覧
   * @property {string} sortColumn - ソートする列名
   * @property {string} sortDirection - ソート順
   * @property {function(element: DOMNode)} tooltipRef
  **/
  static get propTypes() {
    return({
      errorMessage: PropTypes.any,
      onSort: PropTypes.func.isRequired,
      policySetsAvailable: PropTypes.bool.isRequired,
      postProcesses: PropTypes.array.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
      sortColumn: PropTypes.string.isRequired,
      sortDirection: PropTypes.string.isRequired,
    });
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);

    const options = { scope: 'activerecord.attributes.post_process' };
    this.id = I18n.t('id', options);
    this.nameText = I18n.t('name', options);
    this.serviceText = I18n.t('service', options);
    this.updatedAt = I18n.t('updated_at', options);
    this.jobsCountText = I18n.t('javascript.post_process_table.jobs_count');
    this.policiesCountText = I18n.t('javascript.post_process_table.policies_count');
  }

  /**
   * @return {ReactElement}
   */
  render() {
    // THEADタグ内のTHを増減する場合は TABLE_COLUMNS 定数も更新すること
    return(
      <table id="table-post-process" className="table vertical-middle ca-table-header-no-bordered">
        <thead>
          <tr>
            <SortableTableHeaderCell
              active={this.props.sortColumn == 'id'}
              callback={this.props.onSort}
              classNameForHeaderCell="
                ca-post-process-table__clickable-header
                ca-post-process-table__id
              "
              column={'id'}
              direction={this.props.sortDirection}
              text={this.id}
            />
            <SortableTableHeaderCell
              active={this.props.sortColumn == 'name'}
              callback={this.props.onSort}
              classNameForHeaderCell="
                ca-post-process-table__clickable-header
                ca-post-process-table__name
              "
              column={'name'}
              direction={this.props.sortDirection}
              text={this.nameText}
            />
            <SortableTableHeaderCell
              active={this.props.sortColumn == 'service'}
              callback={this.props.onSort}
              classNameForHeaderCell="
                ca-post-process-table__clickable-header
                ca-post-process-table__service
              "
              column={'service'}
              direction={this.props.sortDirection}
              text={this.serviceText}
            />
            <SortableTableHeaderCell
              active={this.props.sortColumn == 'jobs_count'}
              callback={this.props.onSort}
              classNameForHeaderCell="
                ca-post-process-table__clickable-header
                ca-post-process-table__count
                text-center
              "
              column={'jobs_count'}
              direction={this.props.sortDirection}
              text={this.jobsCountText}
              textAlignCenter={true}
            />
            {this.props.policySetsAvailable && (
              <SortableTableHeaderCell
                active={this.props.sortColumn == "policies_count"}
                callback={this.props.onSort}
                classNameForHeaderCell="
                  ca-post-process-table__clickable-header
                  ca-post-process-table__count
                  text-center
                "
                column={"policies_count"}
                direction={this.props.sortDirection}
                text={this.policiesCountText}
                textAlignCenter={true}
              />
            )}
            <SortableTableHeaderCell
              active={this.props.sortColumn == 'updated_at'}
              callback={this.props.onSort}
              classNameForHeaderCell="
                ca-post-process-table__clickable-header
                ca-post-process-table__updatedAt
              "
              column={'updated_at'}
              direction={this.props.sortDirection}
              text={this.updatedAt}
            />
            <th className="ca-post-process-table__clickable-header ca-post-process-table__buttons"></th>
          </tr>
        </thead>
        <tbody>
          {this.getPostProcessRows()}
        </tbody>
      </table>
    );
  }

  /**
   * テーブルに表示する各後処理の後処理テーブル行コンポーネントを配列で返します。
   * @return {array}
   */
  getPostProcessRows() {
    const isPostProcessesExist = (this.props.errorMessage == null && this.props.postProcesses.length > 0);

    if (isPostProcessesExist) {
      return this.props.postProcesses.map((postProcess) => {
        return(
          <PostProcessRow
            key={postProcess.id}
            policySetsAvailable={this.props.policySetsAvailable}
            postProcess={postProcess}
            reloadEmitter={this.props.reloadEmitter}
          />
        );
      });
    }

    const message = this.props.errorMessage != null ? this.props.errorMessage : I18n.t('javascript.post_process_table.no_post_processes');
    return(
      <tr key="0">
        <td colSpan={TABLE_COLUMNS}>{message}</td>
      </tr>
    );
  }
}

/**
 * 後処理テーブル行コンポーネント
 *
 * プロパティ
 * postProcess - 1つの後処理のデータを持つオブジェクト。
 *               サーバーから取得した後処理のJSONデータの postProcess キーに対応する配列の1要素が設定される。
 */
class PostProcessRow extends React.Component {
  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return({
      policySetsAvailable: PropTypes.bool.isRequired,
      postProcess: PropTypes.object.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
    });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const postProcess = this.props.postProcess;
    const options = { scope: 'enumerize.post_process.service' };
    const postProcessServiceText = I18n.t(postProcess.service, options);

    return(
      <tr>
        <td className="text-no-break">{postProcess.id}</td>
        <td className="ca-post-process-table__post-process-name">
          <GroupIconTip group={postProcess.group} />
          <a href={postProcess.url}>{postProcess.name}</a>
        </td>
        <td>{postProcessServiceText}</td>
        <td className="text-center">{postProcess.jobs_count}</td>
        {this.props.policySetsAvailable && <td className="text-center">{postProcess.policies_count}</td>}
        <td>
          <div className="ca-post-process-table__updated-at">
            <PostProcessAvatar user={postProcess.modifier} />
            {postProcess.updated_at}
          </div>
        </td>
        <td className="ca-post-process-table__buttons">
          <PostProcessButtons
            postProcess={postProcess}
            reloadEmitter={this.props.reloadEmitter}
          />
        </td>
      </tr>
    );
  }
}
