import React from "react";
import PropTypes from "prop-types";
import Select2 from "../Select2";

import Constants from "./Constants.js";
import ErrorMessages from "../ErrorMessages.jsx";
import { SelectFilterNote } from "../SelectFilterNote.tsx";

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = `${Constants.I18N_SCOPE}.sqs_aws_account_id_field`;

// SQS用AWSアカウントID選択フィールド
class SqsAwsAccountIdField extends React.Component {
  static get propTypes() {
    return {
      awsAccounts: PropTypes.arrayOf(PropTypes.array).isRequired,
      emitter: PropTypes.object.isRequired,
      loadingImagePath: PropTypes.string.isRequired,
      pending: PropTypes.bool.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      value: PropTypes.string.isRequired,
    };
  }

  // Called immediately after updating occurs. Not called for the initial render.
  componentDidUpdate() {
    // 初回レンダリング時のSelect2のonChangeイベントを無視することで
    // 後処理の編集画面で選択されたAWSアカウントとSQSキューが空で表示される問題を回避する
    this.didFirstRender = true;
  }

  /**
   * エラーがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasError() {
    return this.props.errors.length > 0;
  }

  /**
   * AWSアカウントの選択肢があるかどうかを返します。
   *
   * @return {boolean}
   */
  hasAwsAccounts() {
    return this.props.awsAccounts.length > 0;
  }

  /**
   * Select2のデータを生成します。
   *
   * @return {{id: string, text: string}[]}}
   */
  generateSelect2Data() {
    // AWSアカウントが存在する場合はSelect2のplaceholderを表示するための空の選択肢を追加する
    const blankOption = this.hasAwsAccounts() ? ["", ""] : [I18n.t("empty_options_option", { scope: I18N_SCOPE }), ""];
    const options = [blankOption].concat(this.props.awsAccounts);

    return options.map((item) => {
      const label = item[0];
      const value = item[1];
      return { id: value, text: label };
    });
  }

  render() {
    const label = I18n.t("activerecord.attributes.post_process.sqs_aws_account_id");
    const errorClass = this.hasError() ? "error" : "";
    const blankMessageKey = this.hasAwsAccounts() ? "blank_option" : "empty_options_option";

    return (
      <div className="form-group">
        <div className="post-process-setting">
          <div className="post-process-setting__label paddingT30">
            <div className="post-process-setting__label__text">
              <label htmlFor="PostProcess-sqsAwsAccountId">{label}</label>
              <span className="required">*</span>
            </div>
          </div>
          {this.props.pending && (
            <div>
              <img src={this.props.loadingImagePath} />
            </div>
          )}
          <div
            className={`post-process-setting__form ${errorClass}`}
            style={{ visibility: this.props.pending ? "hidden" : "visible" }}
          >
            {SelectFilterNote()}
            <Select2
              id="PostProcess-sqsAwsAccountId"
              className={`form-control`}
              disabled={!this.hasAwsAccounts()}
              onSelect={(event) => {
                this.props.emitter.emit(Constants.EVENT_CHANGE_SQS_AWS_ACCOUNT_ID, event.target.value);
              }}
              onChange={(event) => {
                // 初回レンダリング以降ではグループの選択変更によりAWSアカウントが空になった場合に
                // 親コンポーネントにAWSアカウントが空になったことを通知することでSQSキューの選択肢を空にする
                if (this.didFirstRender && (event.target.value == null || event.target.value === "")) {
                  this.props.emitter.emit(Constants.EVENT_CHANGE_SQS_AWS_ACCOUNT_ID, event.target.value);
                }
              }}
              defaultValue={this.props.value}
              data={this.generateSelect2Data()}
              options={{
                dropdownAdapter: window.jQuery.fn.select2.amd.require("ForceDropDownAdapter"),
                forceDropdownPosition: "below",
                language: window.jQuery.fn.select2.amd.require(`select2/i18n/${window.I18n.locale}`),
                placeholder: I18n.t(blankMessageKey, { scope: I18N_SCOPE }),
                theme: "kanrinmaru-v2",
                width: "400px",
              }}
            />
            <ErrorMessages messages={this.props.errors} />
          </div>
        </div>
      </div>
    );
  }
}

export default SqsAwsAccountIdField;
